<template>
	<AsiCardDetail :icon="icons.category" :title="$t('ui.terms.basicData')"
	               :show-action-button="!readonly" :action-button-disabled="disabled" @actionButtonClicked="updateDialog.open()">
		<AsiCardDetailEntryTranslatedValue :icon="icons.name" :label="$t('category.name')" :value="category.name"/>
		<AsiCardDetailEntry :icon="icons.color" :icon-color="category.color" :label="$t('category.color')" :value="category.color"/>
		<AsiCardDetailEntry :icon="icons.items" :label="$t('category.showItems')" :value="category.showItems"/>
		<AsiCardDetailEntry :icon="icons.distributeSpacingVertical" :label="$t('category.clampDescription')" :value="category.clampDescription"/>
		<AsiCardDetailEntry :icon="icons.favorite" :label="$t('category.isTopCategory')" :value="category.isTopCategory"/>
		<AsiCardDetailEntry :icon="icons.sort" :label="$t('category.sortIndex')" :value="category.sortIndex"/>
		<AsiCardDetailEntry :icon="icons.view" :label="$t('category.view')" :value="viewValue"/>
		<AsiCardDetailEntry :icon="category.defaultSortDesc ? icons.sortDesc : icons.sort" :label="$t('category.defaultSortBy')" >
			<span v-if="null !== category.defaultSortBy">
				{{$t('item.' + category.defaultSortBy)}} ({{category.defaultSortDesc ? $t('ui.terms.descending') : $t('ui.terms.ascending')}})
			</span>
			<span v-else>-</span>
		</AsiCardDetailEntry>
		<AsiCardDetailEntry :icon="icons.frameAssembly" :label="$t('category.hasFrameAssembly')" :value="category.hasFrameAssembly"/>

		<CategoryUpdateDialogBasicData v-if="!readonly && updateDialog.isLoaded" :open="updateDialog.isOpen" :category="category"
		                               @cancel="updateDialog.close()" @save="updateDialog.close(); change();"/>
	</AsiCardDetail>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import {ICategoryAdmin} from "@/models/category/CategoryAdminModels";
	import Icon from "@/plugins/icons";
	import DialogHandler from "@/components/common/DialogHandler";
	import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
	import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
	import AsiCardDetailEntryTranslatedValue from "@/components/common/AsiCardDetailEntryTranslatedValue.vue";
	import CategoryUpdateDialogBasicData from "@/components/category/admin/CategoryUpdateDialogBasicData.vue";
	import EnumHelper from "@/helpers/EnumHelper";
	import {CategoryView} from "@/helpers/constants";

	@Component({
		components: {
			CategoryUpdateDialogBasicData,
			AsiCardDetailEntryTranslatedValue, AsiCardDetail, AsiCardDetailEntry
		}
	})
	export default class CategoryCardBasicData extends Vue {

		@Prop({type: Object, required: true})
		public category!: ICategoryAdmin;

		@Prop({type: Boolean, default: false})
		public readonly!: boolean;

		@Prop({type: Boolean, default: false})
		public disabled!: boolean;

		private icons = Icon;
		private updateDialog: DialogHandler = new DialogHandler();

		private get viewValue(): string | null {
			return EnumHelper.textFromValue(CategoryView, this.category.view, true);
		}

		@Emit('change')
		public change(): void {
			return;
		}

	}
</script>

<style lang="scss" scoped>

</style>
